var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-headful',{attrs:{"title":_vm.pageTitle}}),_c('div',{staticClass:"has-text-centered has-background-primary",staticStyle:{"margin-bottom":"20px"}},[_c('h1',{staticClass:"is-size-6 has-text-white",staticStyle:{"padding":"5px 0px"}},[_vm._v(" "+_vm._s(_vm.pageheading.toLocaleUpperCase())+" ")])]),_c('div',{staticStyle:{"max-width":"100%","margin":"auto","overflow":"hidden","padding":"1rem"},attrs:{"id":"body-content-area","disabled":_vm.isPendingDelete}},[(_vm.isLoading)?_c('div',[_c('Loading')],1):_vm._e(),_c('div',{class:{ visible: _vm.isLoading },style:({
        visibility: _vm.maxHeight > 0 ? 'visible' : 'hidden',
        overflow: 'hidden',
      })},[_c('div',{attrs:{"id":"pagelayout"}},[_c('div',{staticClass:"top-bar",class:{ visible: _vm.isLoading },staticStyle:{"width":"100%","margin-bottom":"0.5rem","margin-top":"-1rem"}},[_c('div',{staticClass:"grid-header-controls",staticStyle:{"min-width":"100%","width":"100%","margin-top":"0.5rem"}},[_c('div',{staticStyle:{"float":"left"}},[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[(_vm.institutions.length > 0)?_c('Select',{attrs:{"options":_vm.institutionsForSearch,"defaultItem":_vm.selectedInst,"placeholder":'Select Institution',"isDefault":false,"maxItem":10000,"inputStyle":{ height: '36px', marginTop: '1rem' }},on:{"selected":_vm.searchOnChange}}):_vm._e()],1)])]),_c('div',{staticStyle:{"float":"right","margin-top":"1rem"}},[_c('a',{staticClass:"button is-accent",attrs:{"disabled":!_vm.$hasPermissions(_vm.clientSession, ['USERS'], 2)},on:{"click":function($event){!_vm.$hasPermissions(_vm.clientSession, ['USERS'], 2)
                    ? null
                    : _vm.getCsvExport()}}},[_vm._m(0),_c('span',[_vm._v("Export Users")])])]),_c('div',{staticStyle:{"float":"left","margin-left":"0.5rem","margin-top":"1rem"}},[_c('a',{staticClass:"button is-light",on:{"click":_vm.clearFilters}},[_c('span',[_vm._v("Clear Filters")])])]),_c('div',{staticStyle:{"float":"right","margin-right":"0.5rem","margin-top":"1rem"}},[_c('a',{staticClass:"button is-accent",attrs:{"disabled":!_vm.$hasPermissions(_vm.clientSession, ['USERS'], 2)},on:{"click":function($event){_vm.$router.push({
                    name: !_vm.$hasPermissions(_vm.clientSession, ['USERS'], 2)
                      ? ''
                      : 'NewUser',
                    params: {},
                  })}}},[_vm._m(1),_c('span',[_c('router-link',{staticClass:"has-text-white",attrs:{"to":{
                      name: !_vm.$hasPermissions(_vm.clientSession, ['USERS'], 2)
                        ? null
                        : 'NewUser',
                      params: {},
                    }}},[_vm._v(" Add User ")])],1)])]),_c('div',{staticStyle:{"float":"right","margin-right":"0.5rem","margin-top":"1rem"}},[_c('a',{staticClass:"button is-dark",staticStyle:{"min-width":"100px"},attrs:{"disabled":!_vm.$hasPermissions(_vm.clientSession, ['USERS'], 2) ||
                  !_vm.hasSelection},on:{"click":_vm.confirmDeleteLocal}},[(!_vm.isPendingDelete)?_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fal fa-trash-alt"})]):_vm._e(),(!_vm.isPendingDelete)?_c('span',[_vm._v("Delete User"+_vm._s(_vm.hasPluralSelection ? "s" : ""))]):_vm._e(),(_vm.isPendingDelete)?_c('span',[_c('span',{staticClass:"icon is-large"},[_c('spin-loader')],1)]):_vm._e()])])])]),(!_vm.isLoading)?_c('Grid',{style:({ height: _vm.maxHeight + 'px', maxHeight: _vm.maxHeight + 'px' }),attrs:{"filter":_vm.filter,"data-items":_vm.institutionUsers,"selected-field":_vm.selectedField,"sortable":true,"sort":_vm.sort,"filterable":true,"pageable":Object.assign({}, _vm.pageable,
            {pageSizes: _vm.pageSizes}),"page-size":_vm.pageSize,"skip":_vm.skip,"take":_vm.take,"total":_vm.totalRecords,"columns":_vm.columns},on:{"selectionchange":_vm.onSelectionChange,"headerselectionchange":_vm.onHeaderSelectionChange,"filterchange":_vm.filterChangeHandler,"sortchange":_vm.sortChangeHandler,"pagechange":_vm.pageChangeHandler},scopedSlots:_vm._u([{key:"emailTemplate",fn:function(ref){
          var props = ref.props;
          var listeners = ref.listeners;
return [_c('td',[_c('router-link',{staticClass:"bcc-color",attrs:{"to":{
                  name: 'EditUser',
                  params: {
                    id: ("" + (props.dataItem.id)),
                    model: ("" + (JSON.stringify(props.dataItem))),
                    skip: ("" + _vm.skip),
                  },
                }}},[_vm._v(" "+_vm._s(props.dataItem.email)+" ")])],1)]}},{key:"filterSlotTemplate",fn:function(ref){
                var props = ref.props;
                var methods = ref.methods;
return [_c('div',{staticClass:"k-filtercell"},[_c('div',{staticClass:"k-filtercell-wrapper"},[_c('input',{staticClass:"k-textbox",attrs:{"type":"text","id":("" + (props.field))},domProps:{"value":props.value},on:{"input":function (ev) {
                      methods.change({
                        operator: 'contains',
                        field: props.field,
                        value: ev.target.value,
                        syntheticEvent: ev,
                      });
                    }}})])])]}}],null,false,1285497023)}):_vm._e()],1),(_vm.showDeleteModal)?_c('div',{staticClass:"modal is-active",attrs:{"id":"delete-modal"}},[_c('div',{staticClass:"modal-background",on:{"click":function($event){_vm.showDeleteModal = false}}}),_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head has-bg-danger"},[_c('div',{staticClass:"modal-card-title has-bg-danger"},[_vm._v("Confirm Delete")]),_c('a',{staticClass:"delete",attrs:{"aria-label":"close"},on:{"click":function($event){_vm.showDeleteModal = false}}})]),_c('section',{staticClass:"modal-card-body"},[_c('div',{staticClass:"field",staticStyle:{"margin-bottom":"5px"}},[_c('div',{staticClass:"control"},[(_vm.deleteOptions.reportIds.length > 0)?_c('div',[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v(" "+_vm._s(_vm.selectedRows.length > 1 ? "One or more of the selected user(s) have" : "This user has")+" a total of "+_vm._s(_vm.deleteOptions.reportIds.length)+" reports associated with their account(s). Please select a new owner for these reports. ")]),_c('div',{staticClass:"control",staticStyle:{"margin-bottom":"10px"}},[_c('div',{staticClass:"select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.reportsTargetUser),expression:"reportsTargetUser"},{name:"focus",rawName:"v-focus"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.reportsTargetUser=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.usersArr.filter(
                            function (u) { return !_vm.selectedRows.includes(u.id) &&
                              ['Staff', 'Manager'].includes(u.role) &&
                              _vm.deleteOptions.reportUsers.get(u.id); }
                          )),function(opt){return _c('option',{key:opt.id,domProps:{"value":opt.id}},[_vm._v(" "+_vm._s(opt.searchFieldName)+" ")])}),0)]),_c('br')])]):_vm._e(),(_vm.deleteOptions.documentIds.length > 0)?_c('div',[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v(" "+_vm._s(_vm.selectedRows.length > 1 ? "One or more of the selected user(s) have" : "This user has")+" a total of "+_vm._s(_vm.deleteOptions.documentIds.length)+" documents associated with their account(s). Please select a new owner for these documents. ")]),_c('div',{staticClass:"control",staticStyle:{"margin-bottom":"10px"}},[_c('div',{staticClass:"select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.documentsTargetUser),expression:"documentsTargetUser"},{name:"focus",rawName:"v-focus"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.documentsTargetUser=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.usersArr.filter(
                            function (u) { return !_vm.selectedRows.includes(u.id) &&
                              ['Staff', 'Manager'].includes(u.role) &&
                              _vm.deleteOptions.documentUsers.get(u.id); }
                          )),function(opt){return _c('option',{key:opt.id,domProps:{"value":opt.id}},[_vm._v(" "+_vm._s(opt.searchFieldName)+" ")])}),0)]),_c('br')])]):_vm._e(),_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.confirmationText),expression:"confirmationText",modifiers:{"trim":true}}],staticClass:"input",attrs:{"type":"text","placeholder":"type `delete` and then click confirm"},domProps:{"value":(_vm.confirmationText)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.deleteIfConfirmed.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.confirmationText=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})])])]),_c('footer',{staticClass:"modal-card-foot"},[_c('a',{staticClass:"button is-danger",attrs:{"disabled":_vm.confirmationText !== 'delete' ||
                (_vm.deleteOptions.reportIds.length > 0 &&
                  _vm.reportsTargetUser === 0) ||
                (_vm.deleteOptions.documentIds.length > 0 &&
                  _vm.documentsTargetUser === 0)},on:{"click":_vm.deleteIfConfirmed}},[_vm._m(3),_c('span',[_vm._v("Confirm")])]),_c('a',{staticClass:"button",on:{"click":function($event){_vm.showDeleteModal = false}}},[_vm._v("Cancel")])])])]):_vm._e()]),(_vm.maxHeight === 0)?_c('div',[_c('Loading')],1):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fal fa-file-csv"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fal fa-plus-square"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content",staticStyle:{"margin-bottom":"10px"}},[_vm._v(" Type \""),_c('b',[_vm._v("delete")]),_vm._v("\" to confirm that you want to delete one or more users. ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fal fa-trash-alt"})])}]

export { render, staticRenderFns }